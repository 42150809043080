html,
  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #101010;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(255, 255, 255, 0.08);
  min-width: 230px;
  border-radius: 24px;
  position: relative;
  padding: 40px 20px;
}

h6 {
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  opacity: 0.6;
}

.loading {
  display: flex;
  margin-top: 16px;
}

.loading span {
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background-color: #fff;
  margin: 0 4px;
  animation: bounce 1s infinite;
}

.loading span:nth-child(2) {
  animation-delay: 200ms;
}
.loading span:nth-child(3) {
  animation-delay: 400ms;
}
​
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-50%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
